import React, { useContext, useEffect, useState } from 'react';
import { HereContext, IHereContext } from '../Here';
import axios from '../../../services/api';
import { Title, Radio } from '@ftdata/styleguide';
import { get } from 'lodash';
import { BaseLayersMapContainer, ContainerLayers } from './style';
import { useTranslation } from 'react-i18next';

interface Ilayers {
    label: string;
    key: string;
    layer: H.map.layer.BaseTileLayer | H.map.layer.ObjectLayer;
    type: 'base' | 'personalited';
    link_layer: string;
    center?: Array<number>;
}

type propsBaseLayer = {
    url: string;
    copyright: {
        label: string;
        alt: string;
    } | null;
};

type Props = {
    setButtonActive: React.Dispatch<
        React.SetStateAction<{
            active: boolean;
            nameButton: string;
        }>
    >;
    buttonActive: {
        active: boolean;
        nameButton: string;
    };
};

const Layers: React.FC<Props> = ({ setButtonActive, buttonActive }) => {
    const { t } = useTranslation('114');
    const { map, ui } = useContext<IHereContext>(HereContext);
    const [layers, setLayers] = useState<Ilayers[]>([]);
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
    const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" class="H_icon" viewBox="0 0 24 24" stroke="currentColor" style="fill: none;">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7.024L11.987 11.049L21 7.024L12.013 3L3 7.024Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.436 14.4839L21 16.9759L11.987 20.9999L3 16.9759L8.564 14.4909" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.436 9.50781L21 11.9988L11.987 16.0228L3 11.9988L8.564 9.51481" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="tooltip">${t('change_map_layer')}</span>
                `;
    const NAMEBUTTON = 'layer';
    const actualLayer = localStorage.getItem('actualLayer');
    const [checkedBaseLayer, setCheckedBaseLayer] = useState(actualLayer);
    const [container, setContainer] = useState<H.ui.Control | null | undefined>();

    useEffect(() => {
        if (buttonActive.active === true && buttonActive.nameButton !== NAMEBUTTON) {
            const dataContainer = container?.getData();
            const drawing = true;
            container?.setData({ ...dataContainer, drawing });

            const button: H.ui.base.Button = container?.getData().layerButton;
            button?.setState(H.ui.base.Button.State.DOWN);
            button?.setState(H.ui.base.Button.State.UP);
        }
    }, [buttonActive]);

    const addLayerTraffic = () => {
        const layerVetorial = layers.find((layer) => layer.label === 'Vetorial');
        const layerTraffic = layers.find((layer) => layer.label === 'Tráfego');

        if (layerVetorial && layerTraffic) {
            map?.setBaseLayer(layerVetorial.layer);
            map?.addLayer(layerTraffic.layer);
        }
    };

    useEffect(() => {
        const _container = new H.ui.Control();
        const dataContainer = { drawing: false, layerButton: {} };
        _container.setData(dataContainer);
        _container.setAlignment(H.ui.LayoutAlignment.LEFT_TOP);

        const button = new H.ui.base.Button({
            data: null,
            label: icon,
            onStateChange: (event) => {
                const target: any = get(event, 'target');
                if (H.ui.base.Button.State.UP === target.getState()) {
                    const dataContainer = _container?.getData();
                    const drawing = !dataContainer.drawing;

                    _container.setData({ ...dataContainer, drawing });

                    if (drawing) {
                        setButtonActive({ active: true, nameButton: NAMEBUTTON });
                        setIsOpenPopup(true);
                        button.getElement()?.classList?.add('H_active');
                    } else {
                        setButtonActive({ active: false, nameButton: NAMEBUTTON });
                        setIsOpenPopup(false);
                        button.getElement()?.classList.remove('H_active');
                    }
                }
            },
        });

        _container.addChild(button);
        _container.setData({ ...dataContainer, layerButton: button });
        ui?.addControl('layer_control', _container);
        setContainer(_container);

        return () => {
            ui?.removeControl('layer_control');
        };
    }, [map, layers, ui]);

    useEffect(() => {
        const dataContainer = container?.getData();
        const drawing = true;
        container?.setData({ ...dataContainer, drawing });
        const button: H.ui.base.Button = container?.getData().layerButton;
        button?.setState(H.ui.base.Button.State.DOWN);
        button?.setState(H.ui.base.Button.State.UP);

        const validBaselayer = layers.filter((layer) => layer.key === checkedBaseLayer);

        if (
            typeof checkedBaseLayer == 'string' &&
            validBaselayer.length > 0 &&
            objectLayers(checkedBaseLayer) != null
        ) {
            if (checkedBaseLayer == 'here_traffic') {
                addLayerTraffic();
            } else {
                map?.setBaseLayer(objectLayers(checkedBaseLayer).layer);
            }
        } else if (layers.length > 0 && layers[0].layer instanceof H.map.layer.Layer) {
            map?.setBaseLayer(layers[0].layer);
            setCheckedBaseLayer(layers[0].key);
            localStorage.setItem('actualLayer', layers[0].key);
        }
    }, [map, layers]);

    useEffect(() => {
        if (map) {
            axios.get(`/maps/v1/layers/`).then((mapa) => {
                if (mapa && mapa.status === 200) {
                    const _layers: Ilayers[] = [];
                    for (const key in mapa.data) {
                        if (mapa.data[key] === true) {
                            const layerPermitted: Ilayers = objectLayers(key);
                            if (layerPermitted !== undefined) {
                                _layers.unshift(layerPermitted);
                            }
                        }
                    }
                    setLayers(_layers);
                }
            });
        }
    }, [map]);

    const baseImgLayer = ({ url, copyright }: propsBaseLayer) => {
        const tileProvider = new H.map.provider.ImageTileProvider({
            opacity: 1,
            getURL: function (x, y, zoom) {
                const preFormattedUrl = url
                    .replace('{z}', zoom.toString())
                    .replace('{x}', x.toString())
                    .replace('{y}', y.toString());
                return preFormattedUrl;
            },
            getCopyrights: function () {
                return [copyright];
            },
        });

        return tileProvider;
    };

    const objectLayers = (nameLayer: string) => {
        const API_KEY = process.env.REACT_APP_ACCESS_TOKEN_HERE;

        const layers: Record<string, Ilayers> = {
            here_vetorial: {
                label: 'Vetorial',
                key: 'here_vetorial',
                layer: new H.map.layer.BaseTileLayer(
                    baseImgLayer({
                        url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?size=512&apiKey=${API_KEY}&style=explore.day&lang=en`,
                        copyright: {
                            label: '<div class="H_copyright" style="right: 0px; bottom: 0px; position: absolute; background-color: rgba(255, 255, 255, 0.8); color: rgb(15, 22, 33); padding: 2px 16px;"><a target="_blank" href="https://legal.here.com/en/terms/serviceterms/us" style="color: inherit; margin: 0px 8px; text-decoration: none; pointer-events: all;">Terms of use</a><span style="margin: 0px 8px;">© 1987–2021 HERE</span></div>',
                            alt: 'Mapa Here',
                        },
                    }),
                ),
                type: 'base',
                link_layer: '',
            },
            here_sattelite: {
                label: 'Satélite',
                key: 'here_sattelite',
                layer: new H.map.layer.BaseTileLayer(
                    baseImgLayer({
                        url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?size=512&style=explore.satellite.day&apiKey=${API_KEY}&lang=en`,
                        copyright: {
                            label: '<div class="H_copyright" style="right: 0px; bottom: 0px; position: absolute; background-color: rgba(255, 255, 255, 0.8); color: rgb(15, 22, 33); padding: 2px 16px;"><a target="_blank" href="https://legal.here.com/en/terms/serviceterms/us" style="color: inherit; margin: 0px 8px; text-decoration: none; pointer-events: all;">Terms of use</a><span style="margin: 0px 8px;">© 1987–2021 HERE</span></div>',
                            alt: 'Mapa Here',
                        },
                    }),
                ),
                type: 'base',
                link_layer: '',
            },
            here_traffic: {
                label: 'Tráfego',
                key: 'here_traffic',
                layer: new H.map.layer.BaseTileLayer(
                    baseImgLayer({
                        url: `https://traffic.maps.hereapi.com/v3/flow/mc/{z}/{x}/{y}/png?style=explore.day&apiKey=${API_KEY}&lang=en`,
                        copyright: {
                            label: '<div class="H_copyright" style="right: 0px; bottom: 0px; position: absolute; background-color: rgba(255, 255, 255, 0.8); color: rgb(15, 22, 33); padding: 2px 16px;"><a target="_blank" href="https://legal.here.com/en/terms/serviceterms/us" style="color: inherit; margin: 0px 8px; text-decoration: none; pointer-events: all;">Terms of use</a><span style="margin: 0px 8px;">© 1987–2021 HERE</span></div>',
                            alt: 'Mapa Here',
                        },
                    }),
                ),
                type: 'base',
                link_layer: '',
            },
            open: {
                label: 'Open Street Map',
                key: 'open',
                layer: new H.map.layer.BaseTileLayer(
                    baseImgLayer({
                        url: `https://a.tile.osm.org/{z}/{x}/{y}.png`,
                        copyright: {
                            label: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                            alt: 'Open Vetorial',
                        },
                    }),
                ),
                type: 'base',
                link_layer: '',
            },
        };
        return layers[nameLayer];
    };

    const handleOptionChangeBaseLayer = (name: string) => {
        setCheckedBaseLayer(name);
        localStorage.setItem('actualLayer', name);
        const baseLayerClicked = layers.filter((layer) => layer.key === name);

        if (baseLayerClicked.length > 0) {
            if (name == 'here_traffic') {
                addLayerTraffic();
            } else {
                map?.removeLayer(layers[1].layer);
                map?.setBaseLayer(baseLayerClicked[0].layer);
            }
        }
    };

    return (
        <ContainerLayers>
            {isOpenPopup && layers.length > 0 && (
                <>
                    <BaseLayersMapContainer>
                        <Title size="sm">{t('layers_on_the_map')}</Title>
                        {layers.map((layer, index) => (
                            <Radio
                                key={index}
                                disabled={false}
                                onChange={() => handleOptionChangeBaseLayer(layer.key)}
                                checked={checkedBaseLayer === layer.key}
                                value={layer.key}
                                label={layer.label}
                                name={layer.label}
                            ></Radio>
                        ))}
                    </BaseLayersMapContainer>
                </>
            )}
        </ContainerLayers>
    );
};

export default Layers;

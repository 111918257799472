import React, { HTMLAttributes } from 'react';
import { DefaultTagContainer } from './style';

export interface DefaultTagProps extends HTMLAttributes<HTMLDivElement> {
    background?: string;
    color?: string;
    icon?: JSX.Element;
    text?: string;
}

export default function DefaultTag({ background, color, icon, text, ...rest }: DefaultTagProps): JSX.Element {
    return (
        <DefaultTagContainer background={background} color={color} data-testid="default-tag" {...rest}>
            {icon}
            <span>{text}</span>
        </DefaultTagContainer>
    );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';
import { translation } from './i18nTranslations/translation';

const API_URL = 'https://mapageral.ops.fulltrackapp.com';

const backendOptions = {
    loadPath: `${API_URL}/translate/v1/screen/{{ns}}/language/{{lng}}`,
    request: async (options, url, payload, callback) => {
        const tokenStorage = localStorage.getItem('token-hash');
        let token = {
            access_token: '',
        };
        if (tokenStorage) {
            try {
                token = JSON.parse(tokenStorage);
            } catch (error) {
                token = {
                    access_token: '',
                };
            }
        }

        try {
            const translation = await axios.get(url, { headers: { Authorization: 'Bearer ' + token.access_token } });
            callback(null, {
                data: JSON.stringify(translation.data),
                status: 200,
            });
        } catch (e) {
            callback(null, {
                status: 500,
            });
        }
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: false,
        defaultNS: false,
        ns: [],
        supportedLngs: ['en', 'es', 'pt-BR'],
        load: 'currentOnly',
        backend: backendOptions,
    }),
    i18n.on('failedLoading', async function (lng) {
        i18n.addResourceBundle(lng, '114', translation[lng]['114'], true, true);
    });

export default i18n;

import styled from 'styled-components';
import * as styleguide from '@ftdata/tokens';

type PropsCardview = {
    underStateCard?: boolean;
};
export const ContainerCardView = styled.div<PropsCardview>`
    font: 14px 'Lucida Grande', Arial, Helvetica, sans-serif;
    position: absolute;
    top: 3rem;
    right: 3.8rem;
    z-index: 4;
    /* height: max-content; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* width: 350px; */
    width: ${(props) => (!props.underStateCard ? '30px' : '350px')};
    height: ${(props) => (props.underStateCard ? '11rem' : '1.5rem')};
    padding: ${(props) => (!props.underStateCard ? '16px' : '')};
    font-style: normal;
    transition: 1s ease;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    filter: drop-shadow(rgba(107, 117, 124, 0.32) 0px 8px 16px);
`;

export const BodyCardView = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
`;

export const Understate = styled.div`
    text-align: right;

    & > svg {
        cursor: pointer;
    }
`;

export const ContainerNameAtivo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    & > span:first-child {
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: rgb(38, 51, 59);
        text-transform: capitalize;
    }

    & > div {
        display: flex;

        & > svg {
            color: ${styleguide.COLOR_NEUTRAL_DARKER};
        }
    }
`;

export const CollapseListing = styled.button`
    position: absolute;
    top: 62%;
    left: 0%;
    z-index: 3;
    box-shadow: 5px 6px 3px 0px rgb(107 117 124 / 16%);
    border-top: none;
    border-bottom: none;
    border-right: none;
    border-left: ${styleguide.BORDER_WIDTH_HAIRLINE} solid ${styleguide.COLOR_NEUTRAL_LIGHT};
    cursor: pointer;

    height: 52px;
    width: 24px;
    border-radius: 0px 8px 8px 0px;
    background-color: ${styleguide.COLOR_NEUTRAL_DAY};

    & > svg {
        margin-left: -0.5rem;
    }

    ~ button {
        display: none;
    }

    :hover ~ button {
        display: flex;
    }
`;

export const SpanPlate = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: rgb(142, 150, 155);
`;

export const SpanAddress = styled.div`
    font-weight: 500;
    line-height: 160%;
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: rgb(142, 150, 155);
    font-family: Inter;
    font-style: normal;
`;

export const LastComunication = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: rgb(107, 117, 124);
    padding-left: 4px;
`;

export const ContainerStatus = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: ${styleguide.FONT_SIZE_SM};
    line-height: ${styleguide.LINE_HEIGHT_TIGHT};

    & > div {
        margin-right: ${styleguide.SPACING_STACK_02};
        margin-bottom: ${styleguide.SPACING_STACK_02};
    }
`;

export const LoadingContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 28rem;
`;

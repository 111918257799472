import axios, { AxiosError } from 'axios';

const API_URL = 'https://mapageral.ops.fulltrackapp.com';
const GRANT_TYPE = process.env.REACT_APP_API_GRANT_TYPE || '';
const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID_HASH || '';
const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET_HASH || '';
const USER_ID = process.env.REACT_APP_API_USER_ID || '';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: '',
    },
});

export interface TokenProps {
    access_token: string;
    refresh_token: string;
}

let isRefresing = false;

interface FailureRequest {
    onSuccess(token: string): void;
    onFailure(err: AxiosError): void;
}
let failureRequestQueue: FailureRequest[] = [];

function getFormLogin(hash: string) {
    const form = new FormData();
    form.append('grant_type', GRANT_TYPE);
    form.append('client_id', CLIENT_ID);
    form.append('client_secret', CLIENT_SECRET);
    form.append('user_id', USER_ID);
    form.append('anonymous_sharing_hash', hash);
    return form;
}

export const getTokenFulltrack = async (hash: string) => {
    try {
        const form = getFormLogin(hash);
        const { data } = await instance.post('https://api-fulltrack4.fulltrackapp.com/token/anonymous-sharing/', form);

        localStorage.setItem('token-hash', JSON.stringify(data));
        instance.defaults.headers.Authorization = `Bearer ${data.access_token}`;

        return data;
    } catch (error) {
        localStorage.setItem('token-hash', '');
    }
};

instance.interceptors.response.use(
    (res) => res,
    (err: AxiosError) => {
        if (err && err.response && err.response.status === 401) {
            const originalConfig = err.config;
            if (!isRefresing) {
                isRefresing = true;

                const oldToken = JSON.parse(localStorage.getItem('token-hash') || '');
                const form = new FormData();

                form.append('refresh_token', oldToken.refresh_token);
                form.append('grant_type', 'refresh_token');
                instance
                    .post<TokenProps>('/token/refresh', form)
                    .then((responseRefresh) => {
                        if (responseRefresh && responseRefresh.status == 200) {
                            localStorage.setItem('token-hash', JSON.stringify(responseRefresh.data));
                            instance.defaults.headers.Authorization = `Bearer ${responseRefresh.data.access_token}`;
                            failureRequestQueue.forEach((request) => {
                                request.onSuccess(responseRefresh.data.access_token);
                            });
                            failureRequestQueue = [];
                        }
                    })
                    .catch(() => {
                        failureRequestQueue = [];

                        window.location.href = `${window.location.hostname}/dashboard_controller`;
                    })
                    .finally(() => {
                        isRefresing = false;
                    });
            }

            return new Promise((resolve, reject) => {
                const failureRequest: FailureRequest = {
                    onSuccess: (token) => {
                        originalConfig.headers['Authorization'] = `Bearer ${token}`;
                        resolve(instance(originalConfig));
                    },
                    onFailure: (err) => {
                        reject(err);
                    },
                };
                failureRequestQueue.push(failureRequest);
            });
        }
    },
);

export default instance;

import React from 'react';
import moment from 'moment';

interface ShortProps {
    value: string;
}

const Short = ({ value }: ShortProps): JSX.Element => {
    return <span>{moment(value).fromNow()}</span>;
};

export default Short;

import React from 'react';
import * as tokens from '@ftdata/tokens';
import { Icon } from '@ftdata/icons';
import DefaultTag from '../DefaultTag';
import { Tooltips } from '@ftdata/styleguide';
import { useTranslation } from 'react-i18next';

interface BatteryTagProps {
    value: number | undefined;
}

type BatteryType = 'normal' | 'warning' | 'critical';

const verifyType = (value: number): BatteryType => {
    if (value >= 50 && value <= 100) {
        return 'normal';
    }
    if (value > 10 && value < 50) {
        return 'warning';
    }
    return 'critical';
};

const BatteryTag = ({ value }: BatteryTagProps): JSX.Element => {
    const { t } = useTranslation('114');
    const valueInNumber = value || 0;
    const type: BatteryType = verifyType(valueInNumber);

    const iconMap: Record<BatteryType, JSX.Element> = {
        normal: <Icon name="ui battery-charge-5" color={tokens.COLOR_CUSTOM_COLUMNBG} weight="2" />,
        warning: <Icon name="ui battery-charge-5" color={tokens.COLOR_WARNING_DARKER} weight="2" />,
        critical: <Icon name="ui battery-charge-5" color={tokens.COLOR_DANGER_DARKER} weight="2" />,
    };

    const backgroundMap: Record<BatteryType, string> = {
        normal: tokens.COLOR_NEUTRAL_LIGHTER,
        warning: tokens.COLOR_WARNING_LIGHTER,
        critical: tokens.COLOR_DANGER_LIGHTER,
    };

    const fontColorMap: Record<BatteryType, string> = {
        normal: tokens.COLOR_CUSTOM_COLUMNBG,
        warning: tokens.COLOR_WARNING_DARKER,
        critical: tokens.COLOR_DANGER_DARKER,
    };

    return (
        <Tooltips position="bottom" text={t('battery_level').toString()}>
            <DefaultTag
                icon={iconMap[type]}
                background={backgroundMap[type]}
                text={value + '%'}
                color={fontColorMap[type]}
                style={{ gap: '4px' }}
            />
        </Tooltips>
    );
};

export default BatteryTag;

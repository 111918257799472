import styled from 'styled-components';
import * as styleguide from '@ftdata/tokens';

export const ContainerEmptyState = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    & > p {
        width: 320px;
        font-style: normal;
        font-weight: 500;
        font-size: ${styleguide.FONT_SIZE_MD};
        line-height: ${styleguide.LINE_HEIGHT_MEDIUM};
        margin-top: ${styleguide.SPACING_STACK_03};
    }

    & > span {
        width: 320px;
        font-style: normal;
        font-weight: 500;
        font-size: ${styleguide.FONT_SIZE_XL};
        line-height: ${styleguide.LINE_HEIGHT_TIGHT};
        margin-top: ${styleguide.SPACING_STACK_05};
    }

    & > svg {
        margin-top: -116px;
    }
`;

import { FixedSizeList as List } from 'react-window';
import React, { CSSProperties, memo, useEffect, useState } from 'react';
import Tracker from './Tracker';
import { IItem } from '../../../shared/DataStructure';
import { useGeneralMapActiveDetail, useGeneralMapData } from '../../../hooks/useGeneralMap';
import { LoadingContainer } from '../style';
import { Loading } from '@ftdata/styleguide';
import { useSocket } from '../../../contexts/socket';
import { ContainerList } from './styles';

interface TrackerRowProps {
    data: IItem[];
    index: number;
    style: CSSProperties;
}

const ListTracker: React.FC = () => {
    const { updateTracker } = useSocket();
    const { activeDetail, setActiveDetail } = useGeneralMapActiveDetail();
    const { items, loadingTrackers, trackersFiltered } = useGeneralMapData();
    const [trackers, setTrackers] = useState<IItem[]>([] as IItem[]);
    const HEIGTH_FILTER = 73;
    const height = window.innerHeight - HEIGTH_FILTER;

    const renderTrackerRow = ({ data, index, style }: TrackerRowProps) => {
        return <Tracker style={style} tracked={data[index]} key={index} />;
    };

    useEffect(() => {
        if (
            updateTracker.data_type == 'lastposition' &&
            updateTracker &&
            updateTracker.data &&
            updateTracker.data.length
        ) {
            updateTracker.data.forEach(async (_tracker) => {
                const indexTracked: number = trackers.findIndex((tracker) => tracker.ativo_id === _tracker.ativo_id);

                if (indexTracked >= 0) {
                    setTrackers((prevTrackers) => {
                        const newTrackers = [...prevTrackers];
                        newTrackers[indexTracked] = {
                            ...prevTrackers[indexTracked],
                            ..._tracker,
                        };

                        return newTrackers;
                    });
                }

                if (activeDetail && typeof activeDetail == 'object' && activeDetail.ativo_id === _tracker.ativo_id) {
                    setActiveDetail({ ...activeDetail, ..._tracker });
                }
            });
        }
    }, [updateTracker]);

    useEffect(() => {
        if (items.current && items.current.length) {
            setTrackers(items.current);
        }
    }, [loadingTrackers]);

    if (trackersFiltered) {
        return (
            <ContainerList>
                <List
                    className="List"
                    width={'100%'}
                    height={height}
                    itemData={trackersFiltered}
                    itemCount={trackersFiltered.length}
                    itemSize={165}
                >
                    {renderTrackerRow}
                </List>
                {loadingTrackers && (
                    <LoadingContainer>
                        <Loading size={'xl'} variant={'light'} />
                    </LoadingContainer>
                )}
            </ContainerList>
        );
    }

    return (
        <ContainerList>
            <List
                className="List"
                width={'100%'}
                height={height}
                itemData={trackers}
                itemCount={trackers.length}
                itemSize={165}
            >
                {renderTrackerRow}
            </List>
            {loadingTrackers && (
                <LoadingContainer>
                    <Loading size={'xl'} variant={'light'} />
                </LoadingContainer>
            )}
        </ContainerList>
    );
};

export default memo(ListTracker);

import React, { createContext, useState, useContext } from 'react';

interface IConfigurationContext {
    validHash: boolean | null;
    setValidHash: React.Dispatch<React.SetStateAction<boolean | null>>;
    hashPath: string;
    setHash: React.Dispatch<React.SetStateAction<string>>;
}

const HashContext = createContext<IConfigurationContext>({} as IConfigurationContext);
const HashProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [validHash, setValidHash] = useState<boolean | null>(null);
    const [hashPath, setHash] = useState<string>('');

    return (
        <HashContext.Provider value={{ validHash, setValidHash, hashPath, setHash }}>{children}</HashContext.Provider>
    );
};

function useHash(): IConfigurationContext {
    const context = useContext(HashContext);
    return context;
}

export { HashProvider, useHash };

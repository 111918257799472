import React from 'react';
import { HashRouter as Router, Navigate, useRoutes } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound/index';
import './index.css';
import ValidHash from './pages/ValidHash';
import { HashProvider } from './contexts/hash';

const GetRoutes = () => {
    const routes = useRoutes([
        {
            path: '/hash/:hash',
            element: (
                <HashProvider>
                    <ValidHash />
                </HashProvider>
            ),
        },
        { path: '/', element: <PageNotFound /> },
        { path: '*', element: <Navigate to="/" /> },
    ]);
    return routes;
};

const App: React.FC = () => {
    return (
        <Router>
            <GetRoutes />
        </Router>
    );
};

export default App;

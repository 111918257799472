import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as tokens from '@ftdata/tokens';
import DefaultTag from '../DefaultTag';
import { Icon } from '@ftdata/icons';
import { FormatIgnitionString } from '../../Here/Tracking/utils/common';
import { Tooltips } from '@ftdata/styleguide';

interface IgnitionTagProps {
    status: number;
    speed: number;
    time?: string;
}

type IgnitionType = 'on' | 'off' | 'moving';

const IgnitionTag = ({ status, speed, time }: IgnitionTagProps): JSX.Element => {
    const { t } = useTranslation('114');

    const colorMap: Record<IgnitionType, string> = {
        on: tokens.COLOR_NEUTRAL_DAY,
        off: tokens.COLOR_NEUTRAL_DAY,
        moving: tokens.COLOR_NEUTRAL_DAY,
    };

    const textMap: Record<IgnitionType, string> = {
        on: time ? moment(time).format('HH:mm:ss') : t('ignition_on'),
        off: time ? moment(time).format('HH:mm:ss') : t('ignition_off'),
        moving: time ? moment(time).format('HH:mm:ss') : t('on_moving'),
    };

    const iconMap: Record<IgnitionType, JSX.Element> = {
        on: <Icon name="ui minus-circle" color={colorMap['on']} weight="2.5" />,
        off: <Icon name="ui moon" color={colorMap['off']} weight="2.5" />,
        moving: (
            <div style={{ transform: 'rotate(180deg', height: '16px', width: '16px' }}>
                <Icon name="arw arrow-left" color={colorMap['moving']} weight="2.5" />
            </div>
        ),
    };

    const backgroundMap: Record<IgnitionType, string> = {
        on: tokens.COLOR_SUCCESS_DARK,
        off: tokens.COLOR_NEUTRAL_DARKER,
        moving: tokens.COLOR_ACCENT_DARK,
    };

    const type: IgnitionType = FormatIgnitionString(status, speed) as IgnitionType;

    return (
        <Tooltips position="bottom" text={t('commands_status').toString()}>
            <DefaultTag
                icon={iconMap[type]}
                text={textMap[type]}
                background={backgroundMap[type]}
                color={colorMap[type]}
            />
        </Tooltips>
    );
};

export default IgnitionTag;

import styled from 'styled-components';
import * as tokens from '@ftdata/tokens';

export const Container = styled.div`
    font-size: ${tokens.FONT_SIZE_XS};
    font-weight: ${tokens.FONT_WEIGHT_BOLD};
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: ${tokens.SPACING_STACK_02};
`;

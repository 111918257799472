import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface MediumProps {
    value: string;
}

const Medium = ({ value }: MediumProps): JSX.Element => {
    const { t } = useTranslation('114');
    return (
        <span>
            {moment(value).format('DD/MM/YYYY')} {t('at')} {moment(value).format('HH:mm:ss')}
        </span>
    );
};

export default Medium;

import * as styleguide from '@ftdata/tokens';
import Moving from '../../../assets/svgs/imgPopupMarker/right-arrow.svg';
import On from '../../../assets/svgs/imgPopupMarker/stopped-on.svg';
import Off from '../../../assets/svgs/moon.svg';
import DriverIcon from '../../../assets/svgs/driver.svg';

import './style.css';
import { createElement } from '../Tracking/utils/common';

type BubbleStylesType = {
    [key: string]: string;
};

interface BubbleProps {
    ativoName: string;
    plate: string;
    ignitionStatus: string;
    driver_name: string;
    speed: {
        unit_measurement: string;
        val: number;
    };
    battery: number;
    voltage: number;
    translation: {
        on: string;
        moving: string;
        off: string;
        seeDetails: string;
    };
    time: string;
    address?: string;
    customer: string | undefined;
}
export const Bubble = ({
    ativoName,
    plate,
    ignitionStatus,
    driver_name,
    speed,
    battery,
    voltage,
    translation,
    address,
    customer,
    time,
}: BubbleProps): HTMLElement => {
    const nameVehicle = ativoName.toLowerCase();
    const namePlate = plate.toUpperCase();

    const outCommunicationTimeFormated = time;
    const BubbleStyle: Record<string, BubbleStylesType> = {
        moving: {
            iconIgnitionStatus: Moving,
            background: styleguide.COLOR_ACCENT_DARK,
            color: styleguide.COLOR_NEUTRAL_DAY,
            text: translation.moving,
        },
        on: {
            iconIgnitionStatus: On,
            background: styleguide.COLOR_SUCCESS_DARK,
            color: styleguide.COLOR_NEUTRAL_DAY,
            text: translation.on,
        },
        off: {
            iconIgnitionStatus: Off,
            background: styleguide.COLOR_NEUTRAL_DARKER,
            color: styleguide.COLOR_NEUTRAL_DAY,
            text: translation.off,
        },
        speed: {
            color: speed.val == 0 ? styleguide.COLOR_NEUTRAL_DARK : styleguide.COLOR_NEUTRAL_DUSK,
        },
        battery: {
            background:
                battery >= 50 && battery <= 100
                    ? styleguide.COLOR_NEUTRAL_LIGHTER
                    : battery > 10 && battery < 50
                    ? styleguide.COLOR_WARNING_LIGHTER
                    : styleguide.COLOR_DANGER_LIGHTER,

            color:
                battery >= 50 && battery <= 100
                    ? styleguide.COLOR_CUSTOM_COLUMNBG
                    : battery > 10 && battery < 50
                    ? styleguide.COLOR_WARNING_DARKER
                    : styleguide.COLOR_DANGER_DARKER,
        },

        voltage: {
            background:
                voltage >= 50 && voltage <= 100
                    ? styleguide.COLOR_NEUTRAL_LIGHTER
                    : voltage > 10 && voltage < 50
                    ? styleguide.COLOR_WARNING_LIGHTER
                    : styleguide.COLOR_DANGER_LIGHTER,

            color:
                voltage >= 50 && voltage <= 100
                    ? styleguide.COLOR_CUSTOM_COLUMNBG
                    : voltage > 10 && voltage < 50
                    ? styleguide.COLOR_WARNING_DARKER
                    : styleguide.COLOR_DANGER_DARKER,
        },

        communication: {
            color: styleguide.COLOR_NEUTRAL_DARKER,
        },
    };

    const bubble = createElement({
        element: 'div',
        style: null,
    });

    const containerInformations = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'aling-items': 'center',
            'justify-content': 'center',
            'flex-direction': 'column',
            width: '350px',
            'font-style': 'normal',
            background: `${styleguide.COLOR_NEUTRAL_DAY}`,
            'border-radius': '6px',
            filter: 'drop-shadow(0px 8px 16px rgba(107, 117, 124, 0.32))',
        },
    });

    const informations = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'flex-direction': 'column',
            padding: `${styleguide.SPACING_INSET_XS}`,
            'padding-bottom': '0',
        },
    });

    const containerNameLocation = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'flex-direction': 'column',
            'margin-top': '16px',
        },
    });

    const name = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': ' space-between',
            'margin-top': '10px',
        },
    });

    const spanName = createElement({
        element: 'div',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_SM}`,
            color: `${styleguide.COLOR_NEUTRAL_DUSK}`,
            'text-transform': 'capitalize',
        },
        innerText: nameVehicle,
    });

    const communication = createElement({
        element: 'div',
        style: {
            display: 'flex',
        },
        innerHTML: ` <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55897 12.9103L7.4843 10.559C7.75097 10.233 8.24897 10.233 8.51563 10.559L10.441 12.9103C10.7983 13.3456 10.4883 13.9996 9.92563 13.9996H6.07497C5.5123 13.9996 5.2023 13.3456 5.55897 12.9103V12.9103Z" stroke="${BubbleStyle.communication['color']}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5.32617 8.659C5.93617 7.84233 6.90217 7.30566 8.00017 7.30566C9.09817 7.30566 10.0642 7.84166 10.6742 8.659" stroke="${BubbleStyle.communication['color']}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.44385 6.77699C4.54185 5.48699 6.17385 4.66699 8.00051 4.66699C9.82718 4.66699 11.4592 5.48699 12.5572 6.77699" stroke="${BubbleStyle.communication['color']}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.55713 4.89067C3.1418 3.12067 5.43713 2 7.9998 2C10.5625 2 12.8578 3.12067 14.4425 4.89067" stroke="${BubbleStyle.communication['color']}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>`,
    });

    const communicationSpan = createElement({
        element: 'div',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_MEDIUM}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            color: BubbleStyle.communication['color'],
            'padding-left': `${styleguide.SPACING_INLINE_01}`,
        },
        innerText: outCommunicationTimeFormated,
    });

    communication.appendChild(communicationSpan);
    name.appendChild(spanName);
    name.appendChild(communication);

    const spanPlate = createElement({
        element: 'span',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_MEDIUM}`,
            'font-size': `${styleguide.FONT_SIZE_SM}`,
            color: `${styleguide.COLOR_NEUTRAL_DARK}`,
        },
        innerText: namePlate,
    });

    const spanAddress = createElement({
        element: 'span',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_MEDIUM}`,
            'max-width': '270px',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            color: `${styleguide.COLOR_NEUTRAL_DARK}`,
            'font-family': 'Inter',
            'font-style': 'normal',
            'margin-bottom': '8px',
        },
        innerText: address,
    });

    containerNameLocation.appendChild(name);
    containerNameLocation.appendChild(spanPlate);
    containerNameLocation.appendChild(spanAddress);

    const statusBarIgnitionSpeedBatteryVoltage = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'flex-wrap': 'wrap',
            'align-items': 'center',
            gap: '4px',
        },
    });

    const statusBarIgni = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'align-items': 'center',
            gap: '4px',
            padding: '0 4px',
            color: BubbleStyle[ignitionStatus]['color'],
            'border-radius': `${styleguide.BORDER_RADIUS_SM}`,
            'background-color': BubbleStyle[ignitionStatus]['background'],
        },
    });

    const statusBarIgniImg = createElement({
        element: 'svg',
        src: BubbleStyle[ignitionStatus]['iconIgnitionStatus'],
        style: {
            fill: `${styleguide.COLOR_NEUTRAL_DAY}`,
        },
    });

    const statusBarIgniText = createElement({
        element: 'p',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
        },
        innerText: BubbleStyle[ignitionStatus]['text'],
    });

    statusBarIgni.appendChild(statusBarIgniImg);
    statusBarIgni.appendChild(statusBarIgniText);

    const statusBarDriver = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'align-items': ` center`,
            padding: `0px 4px`,
            gap: '4px',
            'background-color': ` ${styleguide.COLOR_BRAND_MEDIUM}`,
            color: ` ${styleguide.COLOR_NEUTRAL_DAY}`,
            'border-radius': ` ${styleguide.BORDER_RADIUS_SM}`,
            'text-transform': ` capitalize`,
            'font-size': ` ${styleguide.FONT_SIZE_XS}`,
            'font-weight': ` ${styleguide.FONT_WEIGHT_BOLD}`,
        },
    });

    const statusBarDriverImg = createElement({
        element: 'svg',
        src: DriverIcon,
    });
    statusBarDriver.appendChild(statusBarDriverImg);

    const statusBarDriverText = createElement({
        element: 'p',
        innerText: driver_name,
    });
    statusBarDriver.appendChild(statusBarDriverText);

    const statusBarSpeed = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'align-items': 'center',
            padding: '0 4px',
            background: `${styleguide.COLOR_NEUTRAL_LIGHTER}`,
            'border-radius': `${styleguide.BORDER_RADIUS_SM}`,
        },
    });

    const statusBarSpeedText = createElement({
        element: 'p',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            margin: 'auto',
            color: BubbleStyle.speed['color'],
        },
        innerText: `${speed.val}  ${speed.unit_measurement}`,
    });
    statusBarSpeed.appendChild(statusBarSpeedText);

    const statusBarBattery = createElement({
        element: 'div',
        style: {
            height: '18px',
            display: 'flex',
            'align-items': 'center',
            padding: '0 4px',
            gap: '4px',
            background: BubbleStyle.battery['background'],
            'border-radius': `${styleguide.BORDER_RADIUS_SM}`,
        },
        innerHTML: `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  stroke="${BubbleStyle.battery['color']}" viewBox="0 0 16 16" fill="none">
                <g id="Group">
                <g id="Group_2">
                <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 12H3.33333C2.59667 12 2 11.4033 2 10.6667V5.33333C2 4.59667 2.59667 4 3.33333 4H11.3333C12.07 4 12.6667 4.59667 12.6667 5.33333V10.6667C12.6667 11.4033 12.07 12 11.3333 12Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Path_2" d="M7.5333 10L8.5333 8H6.1333L7.1333 6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path id="Path_3" d="M12.6667 6H13.3427C13.4461 6 13.5481 6.024 13.6407 6.07067L13.9647 6.23267C14.1907 6.34533 14.3334 6.57667 14.3334 6.82867V9.17133C14.3334 9.424 14.1907 9.65467 13.9647 9.76733L13.6407 9.92933C13.5481 9.976 13.4461 10 13.3427 10H12.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                </g>
            </svg>
        `,
    });

    const statusBarBatteryText = createElement({
        element: 'span',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            color: BubbleStyle.battery['color'],
        },
        innerText: battery.toString() + ' %',
    });
    statusBarBattery.appendChild(statusBarBatteryText);

    const statusBarVoltage = createElement({
        element: 'div',
        style: {
            display: 'flex',
            'align-items': 'center',
            padding: '0 4px',
            gap: '4px',
            background: BubbleStyle.voltage['background'],
            'border-radius': `${styleguide.BORDER_RADIUS_SM}`,
        },
        innerHTML: `<svg width="14" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.6445 1L1.1665 8.33333H5.99984L5.35517 13L10.8332 5.66667H5.99984L6.6445 1Z"
                            stroke="${BubbleStyle.voltage['color']}"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>`,
    });
    const statusBarVoltageText = createElement({
        element: 'span',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            color: BubbleStyle.voltage['color'],
        },
        innerText: voltage.toString() + ' V',
    });
    statusBarVoltage.appendChild(statusBarVoltageText);

    statusBarIgnitionSpeedBatteryVoltage.appendChild(statusBarIgni);
    statusBarIgnitionSpeedBatteryVoltage.appendChild(statusBarDriver);
    statusBarIgnitionSpeedBatteryVoltage.appendChild(statusBarSpeed);
    statusBarIgnitionSpeedBatteryVoltage.appendChild(statusBarBattery);
    statusBarIgnitionSpeedBatteryVoltage.appendChild(statusBarVoltage);

    const nameClient = createElement({
        element: 'div',
        style: {
            'font-weight': `${styleguide.FONT_WEIGHT_BOLD}`,
            'font-size': `${styleguide.FONT_SIZE_XS}`,
            display: 'block',
            color: `${styleguide.COLOR_NEUTRAL_DUSK}`,
            padding: '8px 0 14px 0',
            'line-height': '160%',
        },
        innerText: customer,
    });

    informations.appendChild(containerNameLocation);
    informations.appendChild(statusBarIgnitionSpeedBatteryVoltage);
    informations.appendChild(nameClient);
    containerInformations.appendChild(informations);

    bubble.appendChild(containerInformations);

    return bubble;
};

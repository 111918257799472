import React, { useEffect } from 'react';
import { CollapseListing } from '../Here/style';
import { Button } from '@ftdata/styleguide';
import { useGeneralMapSidebar } from '../../hooks/useGeneralMap';
import { Icon } from '@ftdata/icons';
import * as tokens from '@ftdata/tokens';

export const ToggleSidebar: React.FC = () => {
    const { setShowSidebar, showSidebar } = useGeneralMapSidebar();
    const iconColor = tokens.COLOR_CUSTOM_COLUMNBG;

    useEffect(() => {
        if (window.innerWidth < 700) {
            setShowSidebar(false);
        }
    }, []);

    return (
        <>
            <CollapseListing id="collapse-listing" onClick={() => setShowSidebar(!showSidebar)}>
                {showSidebar ? (
                    <Icon name="arw caret-right" color={iconColor} />
                ) : (
                    <Icon name="arw caret-left" color={iconColor} />
                )}
            </CollapseListing>
            <Button
                onClick={() => setShowSidebar(!showSidebar)}
                variant="primary"
                style={{
                    position: 'absolute',
                    top: '63.5%',
                    left: 30,
                    zIndex: 1,
                    height: '22px',
                    fontSize: '12px',
                    padding: '4px 8px',
                    border: '0px',
                }}
            >
                {showSidebar ? 'Recolher lista lateral' : 'Expandir lista lateral'}
            </Button>
        </>
    );
};

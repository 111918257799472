import React from 'react';
import MapTracking from '../../components/Here/index';
import * as allStyle from './style';
import { useTranslation } from 'react-i18next';
import { useGeneralMapData, useGeneralMapSidebar } from '../../hooks/useGeneralMap';
import { ToggleSidebar } from '../../components/ToggleSidebar';
import { Icon } from '@ftdata/icons';

import * as tokens from '@ftdata/tokens';
import ListTracker from '../../components/Here/ListTracker';

const TemporaryLink = (): JSX.Element => {
    const { t } = useTranslation('114');
    const { items, setTrackersFiltered } = useGeneralMapData();
    const { showSidebar } = useGeneralMapSidebar();

    const filterVehicles = (search: string) => {
        const trackersFiltered = items.current.filter((item) => {
            if (
                item.ativo.ativo_name.toUpperCase().includes(search.trim().toUpperCase()) ||
                item.ativo.plate.toUpperCase().includes(search.trim().toUpperCase())
            ) {
                return item;
            }
        });

        setTrackersFiltered(trackersFiltered);
    };

    return (
        <allStyle.PageContainer>
            {showSidebar && (
                <allStyle.SidebarComponent>
                    <allStyle.ContainerSearch>
                        <allStyle.ContainerInput>
                            <Icon
                                name="ui search-loupe"
                                color={tokens.COLOR_NEUTRAL_DARKER}
                                size={tokens.IC_SIZE_MEDIUM}
                            />
                            <input
                                type="text"
                                placeholder={t('search_by_unit_or_board').toString()}
                                onChange={(e) => {
                                    filterVehicles(e.target.value);
                                }}
                            />
                        </allStyle.ContainerInput>
                    </allStyle.ContainerSearch>
                    <ListTracker />
                </allStyle.SidebarComponent>
            )}
            <allStyle.MapContainer>
                <MapTracking />
                <ToggleSidebar />
            </allStyle.MapContainer>
        </allStyle.PageContainer>
    );
};

export default TemporaryLink;

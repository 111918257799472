import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StopwatchIcon } from '../../../assets/svgs/imageIllustration/stopwatch.svg';
import EmptyState from '../../../components/EmptyState';

export default function InvalidHash(): JSX.Element {
    const { t } = useTranslation('114');

    return (
        <EmptyState
            icon={StopwatchIcon}
            title={t('temporary_link_expired')}
            message={t('time_limit_access_exceeded_contact_responsible')}
        />
    );
}

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTokenFulltrack } from '../../services/api';
import TemporaryLink from '../TemporaryLink';

import { SocketProvider } from '../../contexts/socket';
import { useHash } from '../../contexts/hash';
import { GeneralmapProvider } from '../../contexts/generalmap';
import InvalidHash from './InvalidHash';

type Params = {
    hash: string;
};

const ValidHash = (): JSX.Element => {
    const { hash } = useParams<Params>();
    const { validHash, setValidHash, setHash } = useHash();

    useEffect(() => {
        if (hash) {
            localStorage.setItem('hash-link', hash);
            getTokenFulltrack(hash).then((data) => {
                try {
                    if (data.access_token) {
                        setHash(hash);
                        setValidHash(true);
                    }
                } catch (error) {
                    setValidHash(false);
                }
            });
        }
    }, []);

    if (validHash) {
        return (
            <GeneralmapProvider>
                <SocketProvider>
                    <TemporaryLink />
                </SocketProvider>
            </GeneralmapProvider>
        );
    }

    if (validHash == false) {
        return <InvalidHash />;
    }

    return <div></div>;
};

export default ValidHash;

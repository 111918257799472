import React from 'react';
import { ReactComponent as PageNotFoundIcon } from '../../assets/svgs/imageIllustration/pageNotFound.svg';
import EmptyState from '../../components/EmptyState';
import { useTranslation } from 'react-i18next';

const PageNotFound = (): JSX.Element => {
    const { t } = useTranslation('114');

    return (
        <EmptyState
            icon={PageNotFoundIcon}
            title={t('url_not_found')}
            message={t('ensure_url_entered_is_correct_with_responsible')}
        />
    );
};

export default PageNotFound;

import styled from 'styled-components';
import * as styleguide from '@ftdata/tokens';

type propsGeneralMap = {
    active: boolean;
};

export const ContainerListGeneralMap = styled.div<propsGeneralMap>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: ${styleguide.BORDER_WIDTH_HAIRLINE} solid ${styleguide.COLOR_NEUTRAL_LIGHT};
    background-color: ${({ active }) => active && styleguide.COLOR_NEUTRAL_LIGHTER};
    cursor: pointer;

    :hover {
        background-color: ${styleguide.COLOR_NEUTRAL_LIGHTER};
    }

    & > div {
        display: flex;
    }
`;

export const InformationTracker = styled.div`
    flex: 1 1 0%;
    width: 11rem;

    & > div {
        display: flex;
    }
`;

export const TitleTracker = styled.div`
    font-size: ${styleguide.FONT_SIZE_SM};

    div {
        display: flex;
        color: ${styleguide.COLOR_NEUTRAL_DUSK};
    }

    & > div:nth-child(1) {
        z-index: 1;

        span:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: ${styleguide.SPACING_INLINE_02};
            font-weight: ${styleguide.FONT_WEIGHT_BOLD};
            max-width: 10rem;
            text-transform: capitalize;
        }
    }

    & > div:nth-child(2) {
        span {
            color: ${styleguide.COLOR_NEUTRAL_DARK};
            font-weight: ${styleguide.FONT_WEIGHT_MEDIUM};
        }
    }
`;

export const ContainerAdress = styled.div`
    display: flex;
    margin: ${styleguide.SPACING_STACK_01} 0 ${styleguide.SPACING_STACK_02} 0;

    p {
        font-size: ${styleguide.FONT_SIZE_XS};
        line-height: ${styleguide.LINE_HEIGHT_MEDIUM};
        font-weight: ${styleguide.FONT_WEIGHT_MEDIUM};
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 270px;
        color: ${styleguide.COLOR_NEUTRAL_DARK};
    }
`;

interface FlexContainerProps {
    justifyContent?: string;
    alignItems?: string;
    margin?: string;
    bgColor?: string;
    gap?: string;
    padding?: string;
    height?: string;
    borderRadius?: string;
    flexDirection?: string;
    width?: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
    display: flex;
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'row')};
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
    margin: ${({ margin }) => (margin ? margin : '0px')};
    background-color: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
    gap: ${({ gap }) => (gap ? gap : '0px')};
    padding: ${({ padding }) => (padding ? padding : '0px')};
    height: ${({ height }) => (height ? height : 'initial')};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : 'initial')};
    width: ${({ width }) => (width ? width : 'auto')};
`;

export const InformationNameClient = styled.span`
    display: flex;
    font-size: ${styleguide.FONT_SIZE_XS};
    line-height: ${styleguide.LINE_HEIGHT_MEDIUM};
    text-align: left;
    display: block;
    color: ${styleguide.COLOR_NEUTRAL_DUSK};
    font-weight: ${styleguide.FONT_WEIGHT_MEDIUM};
    text-transform: capitalize;
`;

export const InformationLastComunication = styled.div`
    display: flex;
    height: 25px;

    .tooltipList {
        top: 2.5rem !important;
    }

    & :hover + .tooltipList {
        opacity: 1;
    }
`;

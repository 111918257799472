import styled from 'styled-components';
import * as tokens from '@ftdata/tokens';
import { DefaultTagProps } from '.';

export const DefaultTagContainer = styled.div<DefaultTagProps>`
    align-items: center;
    background-color: ${(props: DefaultTagProps) =>
        props.background ? props.background : tokens.COLOR_NEUTRAL_LIGHTER};
    border-radius: ${tokens.BORDER_RADIUS_SM};
    display: flex;
    gap: 2px;
    height: 16px;
    justify-content: center;
    padding: 1px 4px;

    span {
        color: ${(props: DefaultTagProps) => (props.color ? props.color : tokens.COLOR_CUSTOM_COLUMNBG)};
        font-size: ${tokens.FONT_SIZE_XS};
        font-weight: ${tokens.FONT_WEIGHT_BOLD};
        line-height: 0.9rem;
    }

    svg {
        height: 16px;
        width: 16px;
    }
`;

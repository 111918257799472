import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface LongProps {
    value: string;
}

const Long = ({ value }: LongProps): JSX.Element => {
    const { t } = useTranslation('114');

    return (
        <span>
            {moment(value).fromNow()} {moment(value).format('DD/MM/YYYY')} {t('at')}
            {moment(value).format('HH:mm:ss')}
            {moment(value).fromNow()} {moment(value).format('DD/MM/YYYY')} {t('at')} {moment(value).format('HH:mm:ss')}
        </span>
    );
};

export default Long;

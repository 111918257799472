import H from '@here/maps-api-for-javascript';
import { IconMarker } from '../../IconTracked';
import '../index.css';

export const Icons = new Map<string, H.map.Icon>();

export const getMarkerIcon = (bitmap: string, options?: H.map.Icon.Options): H.map.Icon | undefined => {
    if (!Icons.has(bitmap)) {
        Icons.set(bitmap, new H.map.Icon(bitmap, options));
    }

    return Icons.get(bitmap);
};

export type Icons = Record<number, { moving: H.map.Icon; on: H.map.Icon; off: H.map.Icon; block: H.map.Icon } | null>;
export const getIconsMemorized = (): Icons => {
    const icons: Icons = {};
    for (let type = 1; type <= 20; type++) {
        icons[type] = null;
        icons[type] = {
            moving: new H.map.Icon(
                IconMarker({
                    status: 'moving',
                    type: type,
                }),
            ),
            off: new H.map.Icon(
                IconMarker({
                    status: 'off',
                    type: type,
                }),
            ),
            on: new H.map.Icon(
                IconMarker({
                    status: 'on',
                    type: type,
                }),
            ),
            block: new H.map.Icon(
                IconMarker({
                    status: 'block',
                    type: type,
                }),
            ),
        };
    }

    return icons;
};

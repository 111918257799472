import { useContextSelector } from 'use-context-selector';
import { GeneralMapContext, IGeneralmapContext } from '../contexts/generalmap';

type useGeneralMapSidebarProps = Pick<IGeneralmapContext, 'showSidebar' | 'setShowSidebar'>;

type useGeneralMapActiveDetailProps = Pick<IGeneralmapContext, 'activeDetail' | 'setActiveDetail'>;

type useGeneralMapDataProps = Pick<
    IGeneralmapContext,
    'items' | 'loadingTrackers' | 'setLoadingTrackers' | 'trackersFiltered' | 'setTrackersFiltered'
>;
type useGeneralMapTrailProps = Pick<
    IGeneralmapContext,
    'trailTracker' | 'setTrailTracker' | 'allTrailTracker' | 'setAllTrailTracker'
>;

export function useGeneralMapSidebar(): useGeneralMapSidebarProps {
    const showSidebar = useContextSelector(GeneralMapContext, (generalMap) => generalMap.showSidebar);
    const setShowSidebar = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setShowSidebar);

    return { showSidebar, setShowSidebar };
}

export function useGeneralMapActiveDetail(): useGeneralMapActiveDetailProps {
    const activeDetail = useContextSelector(GeneralMapContext, (generalMap) => generalMap.activeDetail);
    const setActiveDetail = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setActiveDetail);

    return { activeDetail, setActiveDetail };
}

export function useGeneralMapData(): useGeneralMapDataProps {
    const items = useContextSelector(GeneralMapContext, (generalMap) => generalMap.items);
    const loadingTrackers = useContextSelector(GeneralMapContext, (generalMap) => generalMap.loadingTrackers);
    const setLoadingTrackers = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setLoadingTrackers);
    const trackersFiltered = useContextSelector(GeneralMapContext, (generalMap) => generalMap.trackersFiltered);
    const setTrackersFiltered = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setTrackersFiltered);

    return {
        items,
        loadingTrackers,
        setLoadingTrackers,
        trackersFiltered,
        setTrackersFiltered,
    };
}

export function useGeneralMapTrail(): useGeneralMapTrailProps {
    const trailTracker = useContextSelector(GeneralMapContext, (generalMap) => generalMap.trailTracker);
    const allTrailTracker = useContextSelector(GeneralMapContext, (generalMap) => generalMap.allTrailTracker);
    const setTrailTracker = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setTrailTracker);
    const setAllTrailTracker = useContextSelector(GeneralMapContext, (generalMap) => generalMap.setAllTrailTracker);
    return {
        trailTracker,
        allTrailTracker,
        setTrailTracker,
        setAllTrailTracker,
    };
}

import React from 'react';
import { Icon } from '@ftdata/icons';
import { Container } from './styles';
import Short from './Short';
import Medium from './Medium';
import Long from './Long';
import * as tokens from '@ftdata/tokens';

export interface LastCommunicationProps {
    value: string;
    type: Variation;
}

export type Variation = 'short' | 'medium' | 'long' | 'error';

const LastCommunication = ({ value, type }: LastCommunicationProps): JSX.Element => {
    const childrenMap: Record<Variation, JSX.Element> = {
        short: <Short value={value} />,
        medium: <Medium value={value} />,
        long: <Long value={value} />,
        error: <Icon name="ui warning-triangle" weight="2" color={tokens.COLOR_DANGER_DARK} />,
    };

    return (
        <Container>
            {type !== 'error' && <Icon name="ui airplay-signal" weight="2" color={tokens.COLOR_NEUTRAL_DARKER} />}
            {childrenMap[type]}
        </Container>
    );
};

export default LastCommunication;

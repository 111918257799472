import React, { CSSProperties, memo } from 'react';
import * as allStyle from './styles';
import moment from 'moment';
import { IItem } from '../../../../shared/DataStructure';
import LastCommunication from '../../../LastCommunication';
import { Tooltips } from '@ftdata/styleguide';
import { useTranslation } from 'react-i18next';
import { useGeneralMapActiveDetail, useGeneralMapSidebar, useGeneralMapTrail } from '../../../../hooks/useGeneralMap';
import axios from '../../../../services/api';
import InformationTelemetryTracker from './InfoTelemetryTracker';

export interface Props {
    tracked: IItem;
    style?: CSSProperties;
}

const Tracker = ({ tracked, style }: Props) => {
    const { t } = useTranslation('114');
    const { setActiveDetail, activeDetail } = useGeneralMapActiveDetail();
    const { setTrailTracker } = useGeneralMapTrail();
    const { setShowSidebar } = useGeneralMapSidebar();
    const itemSelecioned = typeof activeDetail === 'object' && activeDetail?.ativo_id === tracked.ativo_id;

    const nameVehicle = tracked.ativo.ativo_name.toLowerCase();
    const plate = tracked.ativo.plate.toUpperCase();
    const client = tracked.customer?.description?.toLowerCase();

    const handler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, tracked: IItem) => {
        if (window.innerWidth < 700) {
            setShowSidebar(false);
        }
        setActiveDetail(tracked);

        getLastPosition(tracked.ativo_id).then((data) => {
            const response: IItem[] = data.data;
            if (response && response.length > 0) {
                setTrailTracker(response);
            }
        });
    };

    const getLastPosition = (ativo_id: number) => {
        return axios.get(`/maps/v1/lastpositions/ativo/${ativo_id}/`);
    };

    return (
        <allStyle.ContainerListGeneralMap
            style={{ ...style, padding: '0px 16px', width: '93%' }}
            active={itemSelecioned}
            onClick={(event) => handler(event, tracked)}
        >
            <div>
                <allStyle.InformationTracker>
                    <allStyle.TitleTracker>
                        <div style={{ textTransform: 'capitalize' }}>
                            <Tooltips
                                position={'bottom'}
                                text={nameVehicle.length > 23 ? nameVehicle : t('vehicle').toString()}
                            >
                                <span>{nameVehicle ? nameVehicle : t('not_specified')}</span>
                            </Tooltips>
                        </div>

                        <div>
                            <Tooltips position="bottom" text={t('plate').toString()}>
                                <span>{plate ? plate : t('not_specified')}</span>
                            </Tooltips>
                        </div>
                    </allStyle.TitleTracker>

                    <Tooltips position="right" text={t('desc_address').toString()}>
                        <allStyle.ContainerAdress>
                            <p>{tracked.address}</p>
                        </allStyle.ContainerAdress>
                    </Tooltips>
                </allStyle.InformationTracker>

                <allStyle.InformationLastComunication>
                    <Tooltips
                        style={{ zIndex: 99999999 }}
                        position={tracked.dt_gps ? 'bottom' : 'left'}
                        text={
                            tracked.dt_gps
                                ? t('last_communication').toString()
                                : t('failed_retrieve_last_communication').toString()
                        }
                    >
                        <LastCommunication
                            type={tracked.dt_gps ? 'medium' : 'error'}
                            value={moment(tracked.dt_gps, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSSSZ')}
                        />
                    </Tooltips>
                </allStyle.InformationLastComunication>
            </div>
            <InformationTelemetryTracker tracked={tracked} />

            <allStyle.FlexContainer
                justifyContent={'space-between'}
                flexDirection={'row'}
                alignItems={'flex-start'}
                margin={'0'}
                height={'24px'}
            >
                <Tooltips position="bottom" text={t('customer').toString()}>
                    <allStyle.InformationNameClient>{client}</allStyle.InformationNameClient>
                </Tooltips>
            </allStyle.FlexContainer>
        </allStyle.ContainerListGeneralMap>
    );
};

export default memo(Tracker);

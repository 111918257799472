import moment from 'moment-timezone';
import H from '@here/maps-api-for-javascript';

import { IItem, ISocketTracker, TemperatureFuelProps } from '../../../../shared/DataStructure';
import axios from '../../../../services/api';
export const formatObjectString = (data: Record<string, IItem>, key: string): TemperatureFuelProps => {
    const value = data[key];
    const valueFormated: TemperatureFuelProps = typeof value === 'object' ? value : (0, eval)('(' + value + ')');
    if (valueFormated && Object.keys(valueFormated).length) {
        return valueFormated;
    }
    return null;
};

export const FormatIgnitionString = (ignition: number, speed: number, is_bloqued?: boolean): string => {
    if (is_bloqued) {
        return 'block';
    }
    if (ignition > 0) {
        if (speed > 0) {
            return 'moving';
        } else {
            return 'on';
        }
    } else {
        return 'off';
    }
};

export type OutCominication =
    | {
          id?: number | undefined;
          time_off: string;
          time_on: string;
      }
    | undefined;

export const validOutComunication = (out_comunication: OutCominication, dt_gps: string, ignition: number): boolean => {
    if (!out_comunication) return false;
    const key = ignition ? 'time_on' : 'time_off';
    const secondsOutComunication = moment.duration(out_comunication[key]).asSeconds();
    const diff = moment().diff(moment.unix(parseInt(dt_gps)), 'seconds');
    return diff > secondsOutComunication;
};

const setStyleNode = (node: HTMLElement, style: Record<string, string> | null | undefined): HTMLElement => {
    for (const i in style) {
        node.style.setProperty(i, style[i]);
    }
    return node;
};

export const createElement = ({
    element,
    style,
    innerHTML,
    innerText,
    onClick,
    src,
}: {
    element: string;
    style?: Record<string, string> | null;
    innerHTML?: string;
    innerText?: string;
    onClick?: () => null;
    src?: string;
}): HTMLElement => {
    if (src) {
        const img = document.createElement('img');
        img.src = src;
        return img;
    }

    let node = document.createElement(element);
    if (innerHTML) {
        node.innerHTML = innerHTML;
    }
    if (innerText) {
        node.innerText = innerText;
    }
    if (style) {
        node = setStyleNode(node, style);
    }

    if (onClick) {
        node.onclick = onClick;
    }
    return node;
};

export const serializeDataSocket = (data: ISocketTracker): IItem | false => {
    try {
        const dataUpdate: IItem = {
            temperature:
                (data.temperature &&
                    data.temperature.map((item) => ({
                        desc: item.description,
                        val: item.value_formated.value.toFixed(2).toString(),
                        unit_measurement: item.value_formated.unit,
                    }))) ||
                [],
            fuel:
                (data.fuel &&
                    data.fuel.map((item) => ({
                        desc: item.description,
                        val: item.value_formated.value.toFixed(2).toString(),
                        unit_measurement: item.value_formated.unit,
                    }))) ||
                [],
            speed: { val: data.speed.value || 0, unit_measurement: 'km/h' },
            ativo_id: data.ativo_id || 0,
            ignition: data.ignition || 0,
            dt_gps: data.dt_gps,
            lat_lng: [data.latitude, data.longitude],
            is_bloqued: data?.is_bloqued,
            client_id: data.client_id,
            direction: 0,
            index: data?.index,
            indice_id: data.indice_id,
            driver_name: data?.driver_name,
            ativo: {
                ativo_name: String(data.ativo_name),
                description: String(data.ativo_description) || '',
                plate: String(data.ativo_plate) || '',
                producer: data.driver_name || '',
                horimeter: 0,
                type: data.ativo_type || 1,
            },
        };

        return dataUpdate;
    } catch (error) {
        return false;
    }
};

export interface ICoordinates {
    code: number;
    latitude: number;
    longitude: number;
}

type IaddressReverse = {
    label?: string | undefined;
    description?: string | undefined;
    code: number;
};

export const getAddressReverse = async (data: IItem[]): Promise<IItem[]> => {
    try {
        const coordinates: ICoordinates[] = data.map((item) => ({
            code: item.ativo_id,
            latitude: item.lat_lng[0],
            longitude: item.lat_lng[1],
        }));

        const reverseAddress = await axios.post('/address/v1/reverse/', coordinates);
        if (reverseAddress.status !== 200) return [];
        const dataAddressReverse: IaddressReverse[] = reverseAddress.data;

        const newData = data.map((item) => {
            const addressObj = dataAddressReverse.find((address) => address.code == item.ativo_id) || null;
            return {
                ...item,
                address: addressObj?.description,
            };
        });

        return newData;
    } catch (error) {
        console.error('Erro ao buscar endereços reversos:', error);
        return [];
    }
};

type transformLatLonProps = {
    lat: number;
    lon: number;
    offsetX: number;
    offsetY: number;
    map: H.Map | null | undefined;
};
export const transformLatLon = ({ lat, lon, offsetX = 0, offsetY = 0, map }: transformLatLonProps): H.geo.Point => {
    const point = new H.geo.Point(lat, lon);
    const screenLatLon = map?.geoToScreen(point);
    if (!screenLatLon) return {} as H.geo.Point;
    screenLatLon.x += offsetX;
    screenLatLon.y += offsetY;
    const latlon = map?.screenToGeo(screenLatLon.x, screenLatLon.y);
    if (!latlon) {
        return {} as H.geo.Point;
    }
    return latlon;
};

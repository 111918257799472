import React, { useState, useRef } from 'react';
import { IItem } from '../shared/DataStructure';
import { createContext } from 'use-context-selector';

export interface IGeneralmapContext {
    items: React.MutableRefObject<IItem[]>;
    loadingTrackers: boolean;
    setLoadingTrackers: React.Dispatch<React.SetStateAction<boolean>>;
    trackersFiltered: IItem[] | null;
    setTrackersFiltered: React.Dispatch<React.SetStateAction<IItem[] | null>>;
    allTrailTracker: Record<number, IItem[]> | null;
    setAllTrailTracker: React.Dispatch<React.SetStateAction<Record<number, IItem[]> | null>>;
    trailTracker: IItem[];
    setTrailTracker: React.Dispatch<React.SetStateAction<IItem[]>>;
    activeDetail: IItem | null;
    setActiveDetail: React.Dispatch<React.SetStateAction<IItem | null>>;
    showSidebar: boolean;
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const GeneralMapContext = createContext<IGeneralmapContext>({} as IGeneralmapContext);
const GeneralmapProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const items = useRef<IItem[]>([] as IItem[]);
    const [loadingTrackers, setLoadingTrackers] = useState<boolean>(true);
    const [activeDetail, setActiveDetail] = useState<IItem | null>(null);
    const [trailTracker, setTrailTracker] = useState<IItem[]>([] as IItem[]);
    const [allTrailTracker, setAllTrailTracker] = useState<Record<number, IItem[]> | null>(null);
    const [trackersFiltered, setTrackersFiltered] = useState<IItem[] | null>(null);
    const [showSidebar, setShowSidebar] = useState<boolean>(true);

    return (
        <GeneralMapContext.Provider
            value={{
                items,
                loadingTrackers,
                setLoadingTrackers,
                trackersFiltered,
                setTrackersFiltered,
                activeDetail,
                setActiveDetail,
                trailTracker,
                setTrailTracker,
                allTrailTracker,
                setAllTrailTracker,
                showSidebar,
                setShowSidebar,
            }}
        >
            {children}
        </GeneralMapContext.Provider>
    );
};

export { GeneralmapProvider, GeneralMapContext };

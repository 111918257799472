import * as React from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import { debounce, uniqueId } from 'lodash';
import onResize from 'simple-element-resize-detector';
import H from '@here/maps-api-for-javascript';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';
import './index.css';
import { useTranslation } from 'react-i18next';

export interface HereProps extends H.Map.Options {
    appId: string;
    apikey: string;
    appCode: string;
    language?: string;
    children?: React.ReactNode;
}

export interface IHereContext {
    map?: H.Map;
    ui?: H.ui.UI | null;
    behavior?: H.mapevents.Behavior;
    loadMapHere?: boolean;
}

export const HereContext = createContext<IHereContext>({} as IHereContext);

const Here: React.FC<HereProps> = ({ appId, apikey, appCode, center, language = 'pt-BR', zoom, children }) => {
    const { t } = useTranslation('114');
    const element = useRef<HTMLDivElement>(null);
    const [ui, setUi] = useState<H.ui.UI | null>();
    const [map, setMap] = useState<H.Map>();
    const [behavior, setBehavior] = useState<H.mapevents.Behavior>();
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth - 500,
    });
    const ICON_ZOOM_IN = `<svg viewBox="0 0 32 32" stroke="currentColor" fill="none" class="H_icon_ft" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10V22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 16H10" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="tooltip">${t('zoom_in')}</span>`;

    const ICON_ZOOM_OUT = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="H_icon_ft" fill="none">
            <path d="M22 16H10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="tooltip">${t('zoom_out')}</span>`;

    useEffect(() => {
        if (map) {
            map.getViewPort().resize();
        }
    }, [dimensions, map]);

    useEffect(() => {
        const resizeListener = debounce(() => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth - 500,
            });
        }, 200);

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const changeZoomControl = ({ zoomControl }: Record<string, H.ui.Control>) => {
        const zoomIn = zoomControl?.getChildren()?.[0];
        if (zoomIn instanceof H.ui.base.Button) {
            zoomIn.setLabel(ICON_ZOOM_IN);
            zoomIn.getElement()?.setAttribute('title', '');
        }

        const zoomOut = zoomControl?.getChildren()?.[1];
        if (zoomOut instanceof H.ui.base.Button) {
            zoomOut.setLabel(ICON_ZOOM_OUT);
            zoomOut.getElement()?.setAttribute('title', '');
        }
    };

    useEffect(() => {
        if (!element || !element.current) {
            return;
        }

        const platformOptions = {
            apikey,
            app_id: appId,
            app_code: appCode,
            useHTTPS: true,
        };

        const platform = new H.service.Platform(platformOptions);
        const defaultLayers = platform.createDefaultLayers();

        const _map = new H.Map(element.current, defaultLayers.raster.normal.map, {
            center,
            pixelRatio: window.devicePixelRatio,
            zoom,
        });

        const _ui = H.ui.UI.createDefault(_map, defaultLayers, language);
        const _behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(_map));
        _ui?.removeControl('mapsettings');

        const zoomControl = _ui?.getControl('zoom');
        zoomControl?.setAlignment(H.ui.LayoutAlignment.LEFT_TOP);
        if (zoomControl) {
            changeZoomControl({ zoomControl });
        }

        onResize(
            element.current,
            debounce(() => {
                _map.getViewPort().resize();
            }, 200),
        );

        setBehavior(_behavior);
        setUi(_ui);
        setMap(_map);
    }, [element, appId, appCode, language]);

    return (
        <HereContext.Provider value={{ map, ui, behavior }}>
            <div>
                <div
                    id={`map-container-${uniqueId()}`}
                    ref={element}
                    style={{ display: 'flex' }}
                    className="here-container"
                >
                    {children}
                </div>
            </div>
        </HereContext.Provider>
    );
};

export default Here;

import axios from '../../services/api';
import React, { useRef, useState } from 'react';
import { useContext, useEffect } from 'react';
import { HereContext, IHereContext } from './Here';
import { IItem, LastPositionData } from '../../shared/DataStructure';
import moment from 'moment';
import { FormatIgnitionString, getAddressReverse, validOutComunication } from './Tracking/utils/common';
import { getIconsMemorized, Icons } from './utils/get-marker-icon';
import { useTranslation } from 'react-i18next';
import { Bubble } from './Bubble';
import { useSocket } from '../../contexts/socket';
import { useGeneralMapActiveDetail, useGeneralMapData, useGeneralMapTrail } from '../../hooks/useGeneralMap';

export const getMarkerIcon = (bitmap: string, options?: H.map.Icon.Options): H.map.Icon | undefined => {
    if (!Icons.has(bitmap)) {
        Icons.set(bitmap, new H.map.Icon(bitmap, options));
    }

    return Icons.get(bitmap);
};

interface ItemMapSpeed extends Omit<IItem, 'speed'> {
    speed: number;
}

const Source: React.FC = () => {
    const { t } = useTranslation('114');
    const { map, ui } = useContext<IHereContext>(HereContext);
    const { items, setLoadingTrackers, loadingTrackers } = useGeneralMapData();
    const { activeDetail } = useGeneralMapActiveDetail();
    const { setTrailTracker, setAllTrailTracker, allTrailTracker } = useGeneralMapTrail();
    const { updateTracker } = useSocket();
    const [trailGroup, setTrailGroup] = useState<H.map.Group | null>();
    const [tapBubble, setTapBubble] = useState<Record<number, H.ui.InfoBubble> | null>(null);
    const iconsMemorizedRef = useRef<Icons>();
    const [tooltipTrail] = useState<H.ui.InfoBubble | null>(null);
    const markersRef = useRef<H.map.Marker[] | null>(null);

    const getDriverName = (value: string, unit: number): string => {
        value = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();

        if (unit === 9999) {
            return t('not_specified');
        }

        if (unit === 9998 || value === '') {
            return t('unidentified_driver');
        }

        return value;
    };

    const getMarkerMarkerByAtivo = (markers: H.map.Marker[] | null, idAtivo: number): H.map.Marker | null => {
        if (!markers) return null;
        const indexMarker = markers.findIndex((marker) => marker.getData().ativo_id == idAtivo);

        return markers[indexMarker];
    };

    const pointerenter = () => {
        const mapElement = map?.getElement();
        if (mapElement instanceof HTMLElement) {
            mapElement.style.cursor = 'pointer';
        }
    };

    const pointerleave = () => {
        const mapElement = map?.getElement();
        if (mapElement instanceof HTMLElement) {
            mapElement.style.cursor = 'default';
        }
    };

    const createBubbleByAtivo = async (idAtivo: number, lat: number, lon: number) => {
        const responseTracker = await axios.get(`maps/v2/last-positions/card-views/ativo/${idAtivo}/`);
        let dataTracker: IItem = responseTracker.data.data;

        const paramnsAddress = [
            {
                code: 0,
                latitude: dataTracker.lat_lng[0],
                longitude: dataTracker.lat_lng[1],
            },
        ];

        const resAddress = await axios.post('/address/v1/reverse/', paramnsAddress);
        if (resAddress && resAddress.status == 200) {
            dataTracker = { ...dataTracker, address: resAddress.data[0].description };
        }

        const date = dataTracker.dt_gps;

        const status = FormatIgnitionString(dataTracker.ignition, dataTracker.speed.val, dataTracker.is_bloqued);
        const propsBubble = {
            ativoName: dataTracker.ativo.ativo_name,
            address: dataTracker?.address,
            speed: dataTracker.speed,
            plate: dataTracker.ativo.plate,
            ignitionStatus: status,
            driver_name: getDriverName(
                dataTracker.driver_name == 'PADRAO' ? `${t('not_specified')}` : dataTracker.driver_name,
                Number(dataTracker?.driver_id),
            ),
            battery: dataTracker.battery || 0,
            voltage: dataTracker.voltage || 0,
            customer: dataTracker.customer?.description,
            time: date,
            translation: {
                moving: t('on_moving'),
                on: t('ignition_on'),
                off: t('ignition_off'),
                seeDetails: t('see_details'),
            },
        };

        const bubblesNode = Bubble(propsBubble);
        const bubble = new H.ui.InfoBubble(new H.geo.Point(lat, lon), {
            content: bubblesNode,
        });

        bubble.setData(propsBubble);
        bubble.close = () => {
            setTapBubble(null);
            const markerSelected = getMarkerMarkerByAtivo(markersRef.current, dataTracker.ativo_id);
            if (markerSelected) {
                markerSelected.setData({
                    ...markerSelected.getData(),
                    open: false,
                });
            }

            return { bubble, propsBubble };
        };

        return bubble;
    };

    const tapCreateBubble = async (e: any) => {
        const trackerData: ItemMapSpeed & { open: boolean } = e.target.getData();
        if (!trackerData) return;

        if (trackerData.open) {
            setTapBubble(null);
            e.target.setData({
                ...trackerData,
                open: false,
            });
            return;
        }

        e.target.setData({
            ...trackerData,
            open: true,
        });

        const bubble = await createBubbleByAtivo(trackerData.ativo_id, trackerData.lat_lng[0], trackerData.lat_lng[1]);
        const newTapBubble = { [trackerData.ativo_id]: bubble };
        setTapBubble(newTapBubble);
    };

    async function getTracker() {
        const response = await axios.get<LastPositionData>(
            `/maps/v2/last-positions/card-views/?limit=30&offset=0&ignitions=on,off,moving`,
        );

        if (
            response.status &&
            response.data &&
            response.data.data &&
            response.data.data.length &&
            response.data.data.length > 0
        ) {
            return response.data.data;
        }
    }

    useEffect(() => {
        if (items && items.current.length) {
            const allMarker: H.map.Marker[] = [];

            items.current.map((item) => {
                if (item) {
                    const point = new H.geo.Point(item.lat_lng[0], item.lat_lng[1]);

                    const marker = new H.map.Marker(point, {
                        data: { ...item, trail: true },
                    });

                    const speed = typeof item.speed == 'number' ? item.speed : item.speed.val;
                    const symbol = FormatIgnitionString(item.ignition, speed, item.is_bloqued);
                    item.ativo.type = item.ativo.type > 0 && item.ativo.type <= 20 ? item.ativo.type : 1;
                    const iconTypesStatus: any =
                        iconsMemorizedRef.current && iconsMemorizedRef.current[item.ativo.type];

                    marker.setIcon(iconTypesStatus && iconTypesStatus[symbol]);
                    marker.setData(item);
                    marker.addEventListener('pointerenter', pointerenter, true);
                    marker.addEventListener('pointerleave', pointerleave, true);
                    marker.addEventListener('tap', tapCreateBubble, false);

                    allMarker.push(marker);
                }
            });

            markersRef.current = allMarker;

            const mainGroup = new H.map.Group({
                data: null,
                volatility: true,
                objects: markersRef.current,
            });

            const bounds = mainGroup.getBoundingBox();
            if (bounds) {
                map?.getViewModel().setLookAtData({
                    zoom: 4,
                    bounds,
                });
            }

            setTrailGroup(mainGroup);
        } else {
            setTrailGroup(null);
        }
    }, [loadingTrackers]);

    useEffect(() => {
        if (
            updateTracker.data_type == 'lastposition' &&
            updateTracker &&
            updateTracker.data &&
            updateTracker.data.length
        ) {
            updateTracker.data.forEach(async (_tracker: any) => {
                const getMarkerAtivo = getMarkerMarkerByAtivo(markersRef.current, _tracker.ativo_id);
                if (getMarkerAtivo instanceof H.map.Marker) {
                    const updateMarker = async (marker: H.map.Marker, updateTracker: any) => {
                        updateTracker.speed.val =
                            updateTracker.speed.val.value !== undefined
                                ? updateTracker.speed.val.value
                                : updateTracker.speed.val;
                        const status = FormatIgnitionString(
                            updateTracker.ignition,
                            updateTracker.speed.val,
                            updateTracker.is_bloqued,
                        );
                        updateTracker.ativo.type =
                            updateTracker.ativo.type > 0 && updateTracker.ativo.type <= 20
                                ? updateTracker.ativo.type
                                : 1;

                        const iconTypesStatus: any =
                            iconsMemorizedRef.current && iconsMemorizedRef.current[updateTracker.ativo.type];

                        marker.setData({
                            ..._tracker,
                            lat_lng: [updateTracker.lat_lng[0], updateTracker.lat_lng[1]],
                        });

                        marker.setIcon(iconTypesStatus && iconTypesStatus[status]);
                        marker.setGeometry(new H.geo.Point(updateTracker.lat_lng[0], updateTracker.lat_lng[1], 0));
                    };

                    const paramnsAddress = [
                        {
                            code: 0,
                            latitude: _tracker.lat_lng[0],
                            longitude: _tracker.lat_lng[1],
                        },
                    ];

                    const resAddress = await axios.post('/address/v1/reverse/', paramnsAddress);
                    if (resAddress && resAddress.status == 200) {
                        _tracker = { ..._tracker, address: resAddress.data[0].description };
                    }

                    updateMarker(getMarkerAtivo, _tracker);

                    if (allTrailTracker && allTrailTracker[_tracker.ativo_id]) {
                        allTrailTracker[_tracker.ativo_id].unshift(_tracker);
                        setTrailTracker([...allTrailTracker[_tracker.ativo_id]]);
                        setAllTrailTracker({ ...allTrailTracker });
                    }

                    if (tapBubble && tapBubble[_tracker.ativo_id]) {
                        const bubble = tapBubble[_tracker.ativo_id];
                        const lat = _tracker.lat_lng[0];
                        const lon = _tracker.lat_lng[1];
                        const dt_gps_timestamp = moment(_tracker.dt_gps, 'DD/MM/YYYY HH:mm:ss').format('X');

                        bubble.setPosition(new H.geo.Point(lat, lon));
                        const propsBubble = bubble.getData();
                        const outCommunication =
                            _tracker.out_comunication && Object.values(_tracker.out_comunication).length
                                ? validOutComunication(_tracker.out_comunication, dt_gps_timestamp, _tracker.ignition)
                                : false;
                        const status = FormatIgnitionString(_tracker.ignition, _tracker.speed.val, _tracker.is_bloqued);
                        const speedBubble = {
                            unit_measurement: propsBubble.speed.unit_measurement,
                            val: _tracker.speed.val,
                        };

                        const newProps = {
                            ...propsBubble,
                            address: _tracker.address,
                            speed: speedBubble,
                            ignitionStatus: status,
                            time: _tracker.dt_gps,
                            temperature: _tracker.temperature,
                            translation: {
                                moving: t('on_moving'),
                                on: t('ignition_on'),
                                off: t('ignition_off'),
                            },
                            tapViewDetails: () => null,
                        };

                        bubble.setContent(Bubble(newProps));
                        bubble.setData(newProps);
                        tapBubble[_tracker.ativo_id] = bubble;
                    }
                }
            });
        }
    }, [updateTracker]);

    useEffect(() => {
        if (map) {
            setLoadingTrackers(true);
            iconsMemorizedRef.current = getIconsMemorized();
            getTracker().then(async (data) => {
                if (data) {
                    const result = await getAddressReverse(data);
                    items.current = result;
                    setLoadingTrackers(false);
                }
            });
        }
    }, [map]);

    useEffect(() => {
        if (map && typeof activeDetail == 'object' && activeDetail) {
            map.setCenter({ lat: activeDetail.lat_lng[0], lng: activeDetail.lat_lng[1] });
            map.setZoom(19);

            const activePopup = async () => {
                const bubble = await createBubbleByAtivo(
                    activeDetail.ativo_id,
                    activeDetail.lat_lng[0],
                    activeDetail.lat_lng[1],
                );
                const newTapBubble = { [activeDetail.ativo_id]: bubble };
                setTapBubble(newTapBubble);
            };

            activePopup();
        }
    }, [activeDetail]);

    useEffect(() => {
        if (tapBubble) {
            const tapBubbles = Object.values(tapBubble);
            tapBubbles.map((bubble) => {
                ui?.addBubble(bubble);
            });

            tapBubbles.map((bubble) => {
                bubble.getContentElement()?.classList.add('H_ib_content_bubble_tracker');
                bubble.getContentElement()?.previousElementSibling?.classList.add('H_ib_close_bubble_tracker');
            });
        }

        return () => {
            if (tapBubble) {
                const tapBubbles = Object.values(tapBubble);
                tapBubbles.map((bubble) => {
                    ui?.removeBubble(bubble);
                });
            }
        };
    }, [tapBubble]);

    useEffect(() => {
        if (trailGroup) {
            map?.addObject(trailGroup);
        }
        return () => {
            if (trailGroup) {
                map?.removeObject(trailGroup);
            }
        };
    }, [map, trailGroup]);

    useEffect(() => {
        if (tooltipTrail) {
            ui?.addBubble(tooltipTrail);

            tooltipTrail.getContentElement()?.previousElementSibling?.classList.add('H_ib_close_trail');
            tooltipTrail.getContentElement()?.parentElement?.nextElementSibling?.classList.add('H_ib_tail_trail');
        }
        return () => {
            if (trailGroup && tooltipTrail) {
                ui?.removeBubble(tooltipTrail);
            }
        };
    }, [map, tooltipTrail]);

    return <div></div>;
};

export default Source;

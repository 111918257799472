import React from 'react';
import { Icon } from '@ftdata/icons';
import DefaultTag from '../DefaultTag';
import * as tokens from '@ftdata/tokens';
import { Tooltips } from '@ftdata/styleguide';
import { useTranslation } from 'react-i18next';

interface DriverTagProps {
    value: string;
}

const IconDriver = <Icon name="cs driver" color={tokens.COLOR_NEUTRAL_DAY} />;

const DriverTag = ({ value }: DriverTagProps): JSX.Element => {
    const { t } = useTranslation('114');

    if (value) {
        value = value[0].toUpperCase() + value.substring(1).toLowerCase();
    }

    return (
        <Tooltips position="bottom" text={t('driver').toString()}>
            <DefaultTag
                icon={IconDriver}
                background={tokens.COLOR_BRAND_MEDIUM}
                color={tokens.COLOR_NEUTRAL_DAY}
                text={value}
            />
        </Tooltips>
    );
};

export default DriverTag;

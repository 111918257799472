import React from 'react';
import DefaultTag from '../DefaultTag';
import { Icon } from '@ftdata/icons';
import * as tokens from '@ftdata/tokens';
import { Tooltips } from '@ftdata/styleguide';
import { useTranslation } from 'react-i18next';

interface Props {
    value: number | undefined;
    unit: string;
}

const LightningIcon = (
    <Icon name="ui thunder-lightning-notifications" color={tokens.COLOR_CUSTOM_COLUMNBG} weight="2" />
);

const BatteryVoltageTag = ({ value, unit }: Props): JSX.Element => {
    const { t } = useTranslation('114');

    return (
        <Tooltips position="bottom" text={t('tensao_bateria').toString()}>
            <DefaultTag icon={LightningIcon} text={value + unit} />
        </Tooltips>
    );
};

export default BatteryVoltageTag;

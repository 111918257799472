import React from 'react';
import * as tokens from '@ftdata/tokens';
import DefaultTag from '../DefaultTag';
import { Tooltips } from '@ftdata/styleguide';
import { useTranslation } from 'react-i18next';

interface SpeedTagProps {
    value: number;
    unit: string;
}
type SpeedTagType = 'stopped' | 'moving';

const colorMap: Record<SpeedTagType, string> = {
    stopped: tokens.COLOR_NEUTRAL_DARK,
    moving: tokens.COLOR_CUSTOM_COLUMNBG,
};

const SpeedTag = ({ value, unit }: SpeedTagProps): JSX.Element => {
    const { t } = useTranslation('114');
    const type: SpeedTagType = value > 0 ? 'moving' : 'stopped';

    return (
        <Tooltips position="bottom" text={t('speed').toString()}>
            <DefaultTag color={colorMap[type]} text={`${value} ${unit}`} />
        </Tooltips>
    );
};

export default SpeedTag;

import React from 'react';
import { IItem } from '../../../../../shared/DataStructure';
import { SpeedTag, IgnitionTag, BatteryTag, DriverTag, BatteryVoltageTag } from '../../../../Tags/index';
import { Container } from './style';
import { useTranslation } from 'react-i18next';

interface Props {
    tracked: IItem;
}

export default function InfoTelemetryTracker({ tracked }: Props): JSX.Element {
    const { t } = useTranslation('114');

    //any por conta da api retornar tipos diferentes de objetos
    const verifySpeed = (speed: Record<string, any>): boolean => {
        if (!speed) {
            return false;
        }

        if (speed.hasOwnProperty('value')) {
            tracked.speed = {
                val: speed.value,
                unit_measurement: speed.unit === 'quilometro' ? 'km/h' : speed.unit,
            };

            return true;
        }

        if (speed.hasOwnProperty('val')) {
            return true;
        }

        return false;
    };

    return (
        <Container>
            {(tracked.ignition || tracked.ignition == 0) && verifySpeed(tracked.speed) && (
                <IgnitionTag status={tracked.ignition} speed={tracked.speed.val} />
            )}

            {(tracked.driver_name || tracked.driver_name === '') && (
                <DriverTag
                    value={
                        tracked.driver_name == ''
                            ? t('not_specified')
                            : tracked.driver_name === 'PADRAO'
                            ? t('not_specified')
                            : tracked.driver_name
                    }
                />
            )}

            {verifySpeed(tracked.speed) && <SpeedTag value={tracked.speed.val} unit={tracked.speed.unit_measurement} />}

            {((tracked.battery || tracked.battery === 0) && <BatteryTag value={tracked.battery} />) || ''}

            {((tracked.voltage || tracked.voltage === 0) && <BatteryVoltageTag value={tracked.voltage} unit={'V'} />) ||
                ''}
        </Container>
    );
}

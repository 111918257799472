import React, { useContext, useEffect, useRef, useState } from 'react';
import { useGeneralMapActiveDetail, useGeneralMapTrail } from '../../hooks/useGeneralMap';
import { HereContext, IHereContext } from './Here';
import { getMarkerIcon } from './Source';
import { transformLatLon } from './Tracking/utils/common';
import trail_on from '../../assets/svgs/trail_on.svg';
import trail_off from '../../assets/svgs/trail_off.svg';
import trail_moving from '../../assets/svgs/trail_moving.svg';
import { getIconsMemorized, Icons } from './utils/get-marker-icon';
import { IItem } from '../../shared/DataStructure';
import moment from 'moment';
import axios from '../../services/api';

const Trail: React.FC = () => {
    const { map, ui } = useContext<IHereContext>(HereContext);
    const { activeDetail } = useGeneralMapActiveDetail();
    const { setTrailTracker, trailTracker, setAllTrailTracker, allTrailTracker } = useGeneralMapTrail();
    const [tooltipTrail, setTooltipTrail] = useState<H.ui.InfoBubble | null>(null);
    const [trailGroup, setTrailGroup] = useState<H.map.Group | null>();
    const iconTrails: Record<string, H.map.Icon | undefined> = {
        on: getMarkerIcon(trail_on, { anchor: { x: 10, y: 10 } }),
        off: getMarkerIcon(trail_off, { anchor: { x: 10, y: 10 } }),
        moving: getMarkerIcon(trail_moving, { anchor: { x: 10, y: 10 } }),
    };
    const iconsMemorizedRef = useRef<Icons>();

    const contentPopup = (desc: string) => {
        return `<div style='border-radius: 0.3rem; background-color:white; box-shadow: 0px 4px 8px rgba(107, 117, 124, 0.32); display:block; padding: 0.5rem; rigth: 0.2rem'>
            ${desc || '-'}
        </div>
    `;
    };

    useEffect(() => {
        if (map) {
            iconsMemorizedRef.current = getIconsMemorized();
        }
    }, [map]);

    useEffect(() => {
        if (trailTracker && trailTracker.length > 1) {
            const markerGroup = new H.map.Group({
                data: null,
                visibility: true,
            });

            const arrayPoints = new H.geo.LineString();

            trailTracker.map((item) => {
                const point = new H.geo.Point(item.lat_lng[0], item.lat_lng[1]);
                const icone = item.ignition ? (item.speed.val > 0 ? 'moving' : 'on') : 'off';

                arrayPoints.pushPoint(point);

                const marker = new H.map.Marker(point, {
                    data: { ...item, trail: true },
                    icon: iconTrails[icone],
                });

                marker.addEventListener('pointerenter', () => {
                    const data: IItem = marker.getData();

                    if (typeof data.speed.val !== 'number') {
                        data.speed.val = 1; // caso o campo de speed nao vir um objeto
                    }

                    const dataGps = moment(data.dt_gps, 'DD/MM/YYYY HH:mm:ss').format('HH:mm:ss');
                    const geoStart = transformLatLon({
                        lat: data.lat_lng[0],
                        lon: data.lat_lng[1],
                        offsetX: 70,
                        offsetY: 30,
                        map,
                    });
                    const tooltipInitial = new H.ui.InfoBubble(
                        {
                            lat: geoStart.lat,
                            lng: geoStart.lng,
                        },
                        {
                            content: contentPopup(
                                `<div style="display:flex; justify-content:space-around">
                                    <div>${data.speed.val} Km/h</div>
                                    <div>${dataGps}</div>
                                </div>`,
                            ),
                        },
                    );

                    setTooltipTrail(tooltipInitial);
                });

                marker.addEventListener('pointerleave', () => {
                    setTooltipTrail(null);
                });

                markerGroup.addObject(marker);
            });

            const polyline = new H.map.Polyline(arrayPoints, {
                data: null,
                style: {
                    lineWidth: 5,
                    fillColor: '#316EE8',
                    strokeColor: '#316EE8',
                },
            });
            const mainGroup = new H.map.Group({
                data: null,
                volatility: true,
                objects: [markerGroup, polyline],
            });

            setTrailGroup(mainGroup);
        } else {
            setTrailGroup(null);
        }
    }, [trailTracker]);

    useEffect(() => {
        if (trailGroup) {
            map?.addObject(trailGroup);
        }
        return () => {
            if (trailGroup) {
                map?.removeObject(trailGroup);
            }
        };
    }, [map, trailGroup]);

    useEffect(() => {
        if (tooltipTrail) {
            ui?.addBubble(tooltipTrail);

            tooltipTrail.getContentElement()?.previousElementSibling?.classList.add('H_ib_close_trail');
            tooltipTrail.getContentElement()?.parentElement?.nextElementSibling?.classList.add('H_ib_tail_trail');
        }
        return () => {
            if (trailGroup && tooltipTrail) {
                ui?.removeBubble(tooltipTrail);
            }
        };
    }, [map, tooltipTrail]);

    useEffect(() => {
        if (!activeDetail || typeof activeDetail !== 'object') return;

        if (allTrailTracker && allTrailTracker[activeDetail.ativo_id]) {
            setTrailTracker(allTrailTracker[activeDetail.ativo_id]);
            return;
        }

        axios.get(`/maps/v1/lastpositions/ativo/${activeDetail.ativo_id}/`).then((responseTrail) => {
            if (responseTrail.status !== 200) return;
            setAllTrailTracker({
                [activeDetail.ativo_id]: responseTrail.data,
            });
            setTrailTracker(responseTrail.data);
        });
    }, [activeDetail]);

    return null;
};

export default Trail;

import styled from 'styled-components';
import * as styleguide from '@ftdata/tokens';

export const BaseLayersMapContainer = styled.div`
    width: 11rem;
    max-height: 18.5rem;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${styleguide.SPACING_INLINE_03};
    background: ${styleguide.COLOR_NEUTRAL_DAY};
    box-shadow: ${styleguide.SHADOW_LEVEL_02} rgba(107, 117, 124, 0.32);
    border-radius: ${styleguide.BORDER_RADIUS_SM};

    h5 {
        margin-top: 0;
        font-weight: ${styleguide.FONT_WEIGHT_BOLD};
        margin-bottom: ${styleguide.SPACING_STACK_03};
    }

    label {
        margin: 0;
        margin-bottom: ${styleguide.SPACING_STACK_03};

        p {
            font-size: ${styleguide.FONT_SIZE_XS};
        }
    }
`;

export const ContainerLayers = styled.div`
    position: absolute;
    top: 8.65rem;
    left: 3.8rem;
    z-index: 4;
    height: max-content;

    & > :first-child {
        margin-bottom: ${styleguide.SPACING_STACK_01};
    }
`;

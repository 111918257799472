import styled from 'styled-components';
import * as tokens from '@ftdata/tokens';

export const Container = styled.div`
    text-align: left;
    font-size: ${tokens.FONT_SIZE_XS};
    display: flex;
    line-height: ${tokens.LINE_HEIGHT_MEDIUM};
    color: ${tokens.COLOR_NEUTRAL_DARKER};
    font-weight: ${tokens.FONT_WEIGHT_MEDIUM};

    & > svg {
        margin-right: ${tokens.SPACING_INLINE_02};
        height: 1rem;
        width: 1rem;
    }
`;

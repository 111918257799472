import { en } from './en/en';
import { es } from './es/es';
import { ptBR } from './pt-BR/ptBR';

export const translation = {
    en: {
        '114': {
            ...en,
        },
    },
    es: {
        '114': {
            ...es,
        },
    },
    'pt-BR': {
        '114': {
            ...ptBR,
        },
    },
};

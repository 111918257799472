import styled from 'styled-components';
import * as styleguide from '@ftdata/tokens';

export const SidebarComponent = styled.div`
    height: 100vh;
    width: 28rem;
    box-shadow: 0 16px 20px 12px rgb(107 117 124 / 16%); // criado com valores fixos por falta de variaveis compativeis
    z-index: 2;

    shadow-inner &::-webkit-scrollbar {
        display: none;
    }
`;

export const ContainerSearch = styled.div`
    padding: ${styleguide.SPACING_INSET_XS};
    display: flex;
    border-bottom: ${styleguide.BORDER_WIDTH_HAIRLINE} solid ${styleguide.COLOR_NEUTRAL_LIGHT};
`;

export const ContainerInput = styled.div`
    background-color: ${styleguide.COLOR_NEUTRAL_LIGHTER};
    color: ${styleguide.COLOR_NEUTRAL_DUSK};

    padding: ${styleguide.SPACING_SQUISH_NANO};
    border-radius: ${styleguide.BORDER_RADIUS_SM};
    width: 100%;

    display: flex;
    align-items: center;

    & > svg {
        color: ${styleguide.COLOR_NEUTRAL_DARKER};
        margin-right: ${styleguide.SPACING_INLINE_02};
    }

    & > div:nth-child(3) {
        position: absolute;
        right: 7.8rem;
        color: ${styleguide.COLOR_NEUTRAL_DARKER};
    }

    & > input {
        outline: 2px solid transparent;
        outline-offset: 2px;
        border: none;
        font-size: ${styleguide.FONT_SIZE_SM};
        line-height: ${styleguide.LINE_HEIGHT_MEDIUM};
        font-weight: ${styleguide.FONT_WEIGHT_MEDIUM};
        width: calc(100% - 32px);
        background-color: transparent;
    }
`;

export const ContainerInvalidHash = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    & > p {
        width: 320px;
        font-style: normal;
        font-weight: 500;
        font-size: ${styleguide.FONT_SIZE_MD};
        line-height: ${styleguide.LINE_HEIGHT_MEDIUM};
        margin-top: ${styleguide.SPACING_STACK_03};
    }

    & > span {
        width: 320px;
        font-style: normal;
        font-weight: 500;
        font-size: ${styleguide.FONT_SIZE_XL};
        line-height: ${styleguide.LINE_HEIGHT_TIGHT};
        margin-top: ${styleguide.SPACING_STACK_05};
    }

    & > svg {
        margin-top: -116px;
    }
`;

export const PageContainer = styled.div`
    height: 100vh;
    display: flex;
    position: relative;
`;

export const MapContainer = styled.div`
    position: relative;
    flex: 1 1 0%;
`;

import React, { memo, useState } from 'react';
import Here from './Here';
import Source from './Source';
import Layers from './Layers/Layers';
import Trail from './Trail';

const REACT_APP_ACCESS_TOKEN_HERE: string = process.env.REACT_APP_ACCESS_TOKEN_HERE || '';
const MapTracking = (): JSX.Element => {
    const [buttonActive, setButtonActive] = useState({ active: false, nameButton: '' });

    return (
        <Here
            apikey={REACT_APP_ACCESS_TOKEN_HERE}
            appId="full-track-id"
            appCode="full-track-code"
            center={{ lat: -22.081453, lng: -49.692326 }}
            zoom={7}
        >
            <Source />
            <Trail />
            <Layers buttonActive={buttonActive} setButtonActive={setButtonActive} />
        </Here>
    );
};

export default memo(MapTracking);
